import React, { useState, useEffect, useContext } from "react"
import { Link } from "components/anti/link/link"
// import { Button } from "components/anti/buttons/buttons"

import AboutSubmenu from "./desktop-about-submenu"
import ServicesSubmenu from "./desktop-services-submenu"
import InvestorSubmenu from "./destop-investor-submenu"
import DevelopmentSubmenu from "./desktop-development-submenu"
import { graphql, useStaticQuery } from "gatsby"

const DesktopMenu = ({
  navExpand,
  handleSearch,
  disabledSearch,
  setNavOpen,
  lang,
  path,
}) => {
  const [aboutActive, setAboutActive] = useState(false)
  const [investorActive, setInvestorActive] = useState(false)
  const [developmentActive, setDevelopmentActive] = useState(false)
  const [servicesActive, setServicesActive] = useState(false)

  const [aboutSubmenu, setAboutSubmenu] = useState({ isOpen: false })
  const [servicesSubmenu, setServicesSubmenu] = useState({ isOpen: false })
  const [investorSubmenu, setInvestorSubmenu] = useState({ isOpen: false })
  const [developmentSubmenu, setDevelopmentSubmenu] = useState({
    isOpen: false,
  })

  const [subData, setSubData] = useState({ label: "", data: [] })
  const [disabledBtn, setDisabledBtn] = useState(true)

  const data = useStaticQuery(GET_HEADER_MENU)
  const menu =
    lang === "id"
      ? data.wordPress.translation.nodes[0].menuItems.nodes
      : data.wordPress.menus.nodes[0].menuItems.nodes

  const aboutToggler = () => {
    setDisabledBtn(true)
    if (!disabledBtn) {
      setAboutSubmenu({ isOpen: !aboutSubmenu.isOpen })
      if (!aboutSubmenu.isOpen === false) {
        setTimeout(() => setNavOpen(!aboutSubmenu.isOpen), 600)
      } else {
        setNavOpen(!aboutSubmenu.isOpen)
      }

      if (servicesSubmenu.isOpen) {
        setServicesSubmenu({ isOpen: false })
      } else if (investorSubmenu.isOpen) {
        setInvestorSubmenu({ isOpen: false })
      } else if (developmentSubmenu.isOpen) {
        setDevelopmentSubmenu({ isOpen: false })
      }
    }
  }

  const investorToggler = () => {
    setDisabledBtn(true)
    if (!disabledBtn) {
      setInvestorSubmenu({ isOpen: !investorSubmenu.isOpen })
      if (!investorSubmenu.isOpen === false) {
        setTimeout(() => setNavOpen(!investorSubmenu.isOpen), 600)
      } else {
        setNavOpen(!investorSubmenu.isOpen)
      }

      if (aboutSubmenu.isOpen) {
        setAboutSubmenu({ isOpen: false })
      } else if (servicesSubmenu.isOpen) {
        setServicesSubmenu({ isOpen: false })
      } else if (developmentSubmenu.isOpen) {
        setDevelopmentSubmenu({ isOpen: false })
      }
    }
  }

  const serviceToggler = () => {
    setDisabledBtn(true)
    if (!disabledBtn) {
      setServicesSubmenu({ isOpen: !servicesSubmenu.isOpen })
      if (!servicesSubmenu.isOpen === false) {
        setTimeout(() => setNavOpen(!servicesSubmenu.isOpen), 600)
      } else {
        setNavOpen(!servicesSubmenu.isOpen)
      }

      if (aboutSubmenu.isOpen) {
        setAboutSubmenu({ isOpen: false })
      } else if (investorSubmenu.isOpen) {
        setInvestorSubmenu({ isOpen: false })
      } else if (developmentSubmenu.isOpen) {
        setDevelopmentSubmenu({ isOpen: false })
      }
    }
  }

  const developmentToggler = () => {
    setDisabledBtn(true)
    if (!disabledBtn) {
      setDevelopmentSubmenu({ isOpen: !developmentSubmenu.isOpen })
      if (!developmentSubmenu.isOpen === false) {
        setTimeout(() => setNavOpen(!developmentSubmenu.isOpen), 600)
      } else {
        setNavOpen(!developmentSubmenu.isOpen)
      }

      if (aboutSubmenu.isOpen) {
        setAboutSubmenu({ isOpen: false })
      } else if (investorSubmenu.isOpen) {
        setInvestorSubmenu({ isOpen: false })
      } else if (servicesSubmenu.isOpen) {
        setServicesSubmenu({ isOpen: false })
      }
    }
  }

  const toogler = (sectionName, items) => {
    if (!disabledBtn) {
      setSubData({ label: sectionName, data: items })
      switch (sectionName) {
        case "About":
        case "Tentang":
          return aboutToggler()
        case "Investor's Area":
        case "Area Investor":
          return investorToggler()
        case "Development":
        case "Pengembangan":
          return developmentToggler()
        case "Services":
        case "Layanan":
          return serviceToggler()
      }
    }
  }

  // for active dropdown class
  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname?.indexOf("about") >= 0) {
      setAboutActive(true)
    } else if (pathname?.indexOf("investor") >= 0) {
      setInvestorActive(true)
    } else if (pathname?.indexOf("development") >= 0) {
      setDevelopmentActive(true)
    } else if (pathname?.indexOf("services") >= 0) {
      setServicesActive(true)
    }
  }, [])

  useEffect(() => {
    if (disabledBtn) {
      setTimeout(() => setDisabledBtn(false), 1000)
    }
  }, [disabledBtn])

  return (
    <>
      <div className={`desktop-menu d-none d-${navExpand}-flex`}>
        <ul className="navbar-nav ml-auto">
          {menu?.map((item, i) => {
            if (item.url === "#") {
              let section =
                item.label === "About" || item.label === "Tentang"
                  ? aboutActive
                  : item.label === "Development" ||
                    item.label === "Pengembangan"
                  ? developmentActive
                  : item.label === "Investor's Area" ||
                    item.label === "Area Investor"
                  ? investorActive
                  : item.label === "Services" || item.label === "Layanan"
                  ? servicesActive
                  : false
              return (
                <li key={i} className="nav-item">
                  <div
                    className={`nav-link ${section && "active"}`}
                    onClick={() => toogler(item.label, item.childItems.nodes)}
                  >
                    {item.label}
                  </div>
                </li>
              )
            } else {
              return (
                <li key={i} className="nav-item">
                  <Link
                    key={i}
                    className="nav-link"
                    // activeClassName="active"
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                </li>
              )
            }
          })}
          <li className="nav-item">
            <button
              className="nav-link"
              disabledSearch={disabledSearch}
              onClick={handleSearch}
            >
              <i className="air ai-search" />
            </button>
          </li>
        </ul>
      </div>

      <AboutSubmenu
        navExpand={navExpand}
        state={aboutSubmenu}
        setState={setAboutSubmenu}
        lang={lang}
        subData={subData}
      />
      <ServicesSubmenu
        navExpand={navExpand}
        state={servicesSubmenu}
        setState={setServicesSubmenu}
        lang={lang}
        subData={subData}
      />
      <InvestorSubmenu
        navExpand={navExpand}
        state={investorSubmenu}
        setState={setInvestorSubmenu}
        lang={lang}
        subData={subData}
      />
      <DevelopmentSubmenu
        navExpand={navExpand}
        state={developmentSubmenu}
        setState={setDevelopmentSubmenu}
        lang={lang}
        subData={subData}
      />
    </>
  )
}

export default DesktopMenu

const GET_HEADER_MENU = graphql`
  query GET_HEADER_MENU {
    wordPress {
      menus(where: { location: HEADER_MENU }) {
        nodes {
          menuItems(where: { parentId: 0 }) {
            nodes {
              parentId
              label
              url
              locations
              childItems(first: 15) {
                nodes {
                  parentId
                  label
                  url
                }
              }
            }
          }
        }
      }
      translation: menus(where: { location: HEADER_MENU___ID }) {
        nodes {
          menuItems(where: { parentId: 0 }) {
            nodes {
              parentId
              label
              url
              locations
              childItems(first: 15) {
                nodes {
                  parentId
                  label
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
